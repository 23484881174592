/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { getEpsilon as t } from "./common.js";
function n(t, n) {
  return t[0] = n[0], t[1] = n[1], t[2] = n[2], t[3] = n[3], t[4] = n[4], t[5] = n[5], t;
}
function a(t) {
  return t[0] = 1, t[1] = 0, t[2] = 0, t[3] = 1, t[4] = 0, t[5] = 0, t;
}
function r(t, n, a, r, u, o, e) {
  return t[0] = n, t[1] = a, t[2] = r, t[3] = u, t[4] = o, t[5] = e, t;
}
function u(t, n) {
  const a = n[0],
    r = n[1],
    u = n[2],
    o = n[3],
    e = n[4],
    s = n[5];
  let c = a * o - r * u;
  return c ? (c = 1 / c, t[0] = o * c, t[1] = -r * c, t[2] = -u * c, t[3] = a * c, t[4] = (u * s - o * e) * c, t[5] = (r * e - a * s) * c, t) : null;
}
function o(t) {
  return t[0] * t[3] - t[1] * t[2];
}
function e(t, n, a) {
  const r = n[0],
    u = n[1],
    o = n[2],
    e = n[3],
    s = n[4],
    c = n[5],
    i = a[0],
    M = a[1],
    h = a[2],
    f = a[3],
    b = a[4],
    l = a[5];
  return t[0] = r * i + o * M, t[1] = u * i + e * M, t[2] = r * h + o * f, t[3] = u * h + e * f, t[4] = r * b + o * l + s, t[5] = u * b + e * l + c, t;
}
function s(t, n, a) {
  const r = n[0],
    u = n[1],
    o = n[2],
    e = n[3],
    s = n[4],
    c = n[5],
    i = Math.sin(a),
    M = Math.cos(a);
  return t[0] = r * M + o * i, t[1] = u * M + e * i, t[2] = r * -i + o * M, t[3] = u * -i + e * M, t[4] = s, t[5] = c, t;
}
function c(t, n, a) {
  const r = n[0],
    u = n[1],
    o = n[2],
    e = n[3],
    s = n[4],
    c = n[5],
    i = a[0],
    M = a[1];
  return t[0] = r * i, t[1] = u * i, t[2] = o * M, t[3] = e * M, t[4] = s, t[5] = c, t;
}
function i(t, n, a) {
  const r = n[0],
    u = n[1],
    o = n[2],
    e = n[3],
    s = n[4],
    c = n[5],
    i = a[0],
    M = a[1];
  return t[0] = r, t[1] = u, t[2] = o, t[3] = e, t[4] = r * i + o * M + s, t[5] = u * i + e * M + c, t;
}
function M(t, n) {
  const a = Math.sin(n),
    r = Math.cos(n);
  return t[0] = r, t[1] = a, t[2] = -a, t[3] = r, t[4] = 0, t[5] = 0, t;
}
function h(t, n) {
  return t[0] = n[0], t[1] = 0, t[2] = 0, t[3] = n[1], t[4] = 0, t[5] = 0, t;
}
function f(t, n) {
  return t[0] = 1, t[1] = 0, t[2] = 0, t[3] = 1, t[4] = n[0], t[5] = n[1], t;
}
function b(t) {
  return "mat2d(" + t[0] + ", " + t[1] + ", " + t[2] + ", " + t[3] + ", " + t[4] + ", " + t[5] + ")";
}
function l(t) {
  return Math.sqrt(t[0] ** 2 + t[1] ** 2 + t[2] ** 2 + t[3] ** 2 + t[4] ** 2 + t[5] ** 2 + 1);
}
function m(t, n, a) {
  return t[0] = n[0] + a[0], t[1] = n[1] + a[1], t[2] = n[2] + a[2], t[3] = n[3] + a[3], t[4] = n[4] + a[4], t[5] = n[5] + a[5], t;
}
function d(t, n, a) {
  return t[0] = n[0] - a[0], t[1] = n[1] - a[1], t[2] = n[2] - a[2], t[3] = n[3] - a[3], t[4] = n[4] - a[4], t[5] = n[5] - a[5], t;
}
function p(t, n, a) {
  return t[0] = n[0] * a, t[1] = n[1] * a, t[2] = n[2] * a, t[3] = n[3] * a, t[4] = n[4] * a, t[5] = n[5] * a, t;
}
function x(t, n, a, r) {
  return t[0] = n[0] + a[0] * r, t[1] = n[1] + a[1] * r, t[2] = n[2] + a[2] * r, t[3] = n[3] + a[3] * r, t[4] = n[4] + a[4] * r, t[5] = n[5] + a[5] * r, t;
}
function y(t, n) {
  return t[0] === n[0] && t[1] === n[1] && t[2] === n[2] && t[3] === n[3] && t[4] === n[4] && t[5] === n[5];
}
function S(n, a) {
  const r = n[0],
    u = n[1],
    o = n[2],
    e = n[3],
    s = n[4],
    c = n[5],
    i = a[0],
    M = a[1],
    h = a[2],
    f = a[3],
    b = a[4],
    l = a[5],
    m = t();
  return Math.abs(r - i) <= m * Math.max(1, Math.abs(r), Math.abs(i)) && Math.abs(u - M) <= m * Math.max(1, Math.abs(u), Math.abs(M)) && Math.abs(o - h) <= m * Math.max(1, Math.abs(o), Math.abs(h)) && Math.abs(e - f) <= m * Math.max(1, Math.abs(e), Math.abs(f)) && Math.abs(s - b) <= m * Math.max(1, Math.abs(s), Math.abs(b)) && Math.abs(c - l) <= m * Math.max(1, Math.abs(c), Math.abs(l));
}
const _ = e,
  g = d,
  j = Object.freeze(Object.defineProperty({
    __proto__: null,
    add: m,
    copy: n,
    determinant: o,
    equals: S,
    exactEquals: y,
    frob: l,
    fromRotation: M,
    fromScaling: h,
    fromTranslation: f,
    identity: a,
    invert: u,
    mul: _,
    multiply: e,
    multiplyScalar: p,
    multiplyScalarAndAdd: x,
    rotate: s,
    scale: c,
    set: r,
    str: b,
    sub: g,
    subtract: d,
    translate: i
  }, Symbol.toStringTag, {
    value: "Module"
  }));
export { m as add, n as copy, o as determinant, S as equals, y as exactEquals, l as frob, M as fromRotation, h as fromScaling, f as fromTranslation, a as identity, u as invert, j as m, _ as mul, e as multiply, p as multiplyScalar, x as multiplyScalarAndAdd, s as rotate, c as scale, r as set, b as str, g as sub, d as subtract, i as translate };